import { Route } from '@angular/router';
// import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';

import { NoAuthGuard } from './shared/guards/no-auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Sign In & Sign Up
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/pages/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/pages/auth/sign-up/sign-up.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/pages/auth/forgot-password/forgot-password.routes')},
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: 'tandaplus',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'classy'
        },
        children: [
            { path: 'nueva-tanda', loadChildren: () => import('app/pages/tanda/nueva-tanda/nueva-tanda.routes') },
            
            { path: '**', pathMatch: 'full', redirectTo: 'nueva-tanda' }
        ]
    },

    // Redirects to sign-in
    { path: '**', pathMatch: 'full', redirectTo: 'auth/sign-in' },
];
