import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const NoAuthGuard: CanActivateFn = (route, state) => {
  
  const router: Router = inject(Router);

  const session = sessionStorage.getItem('access_token') || undefined;

  if (session === undefined) {
   
    return true;
  } else {
  
    router.navigate(['tandaplus', 'inicio']);
  }

  
};
